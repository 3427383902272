import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@akinon/next/components/mobile-app-toggler.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
;
import(/* webpackMode: "eager" */ "/app/src/components/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalModal"] */ "/app/src/views/global-modal/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/header-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/footer-subscription/index.tsx");
